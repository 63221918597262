<template>
  <v-row>
    <v-col
      cols="12"
      md="12"
    >
      <list-of-internment-today></list-of-internment-today>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <list-of-internment-not-yet-schedule></list-of-internment-not-yet-schedule>
    </v-col>
  </v-row>
</template>

<script>

  import ListOfInternmentToday from './ListOfInternmentToday.vue'
  import ListOfInternmentNotYetSchedule from './ListOfInternmentNotYetSchedule.vue'

  export default {
    components: {
      ListOfInternmentToday,
      ListOfInternmentNotYetSchedule,
    },
  }
</script>
