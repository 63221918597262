<template>
  <div>
    <v-card class="greeting-card">
      <v-row class="ma-0 pa-0">
        <v-col cols="8">
          <v-card-title class="text-no-wrap pt-1 ps-2">
            List of Deceased Not Yet In Schedule of Interment
          </v-card-title>
        </v-col>

        <v-card-text>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-uppercase">FSC#</th>
                <th class="text-center text-uppercase">Name</th>
                <th class="text-center text-uppercase">Date of Service</th>
                <th class="text-center text-uppercase">Date of Internment</th>
                <th class="text-center text-uppercase">Mode of Service</th>
                <th class="text-center text-uppercase">Details</th>
                <th class="text-center text-uppercase">Casket Type</th>
                <th class="text-center text-uppercase">Total Payables</th>
                <th class="text-center text-uppercase">Balance</th>
                <th class="text-center text-uppercase">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in list_of_deceased_items_today" :key="item.id">
                <td>{{ item.fsc_no }}</td>
                <td class="text-center">
                  {{ item.last_name+', '+item.first_name+' '+item.middle_name }}
                </td>
                <td class="text-center">
                  {{ item.date_of_service }}
                </td>
                <td class="text-center">
                  {{ item.date_of_internment }}
                </td>
                <td class="text-center">
                  {{ item.mode_of_service}}
                </td>
                <td class="text-center">
                  {{ (item.damayan_branch===null?'':item.damayan_branch+' '+item.maf_no)
                  +(item.kaagapay===null?'':item.kaagapay.last_name+', '+item.kaagapay.first_name)}}
                </td>
                <td class="text-center">
                  {{ item.casket_type}}
                </td>
                <td class="text-center">
                  {{ formatPrice(item.total)}}
                </td>
                <td class="text-center">
                  {{ formatPrice(item.total)}}
                </td>
                <td class="text-center">
                  <div>
                    <v-icon
                      v-if="!is_done_internment"
                      class="mr-2"
                      color="error"
                      @click="edit_charge(list_of_deceased_items_today.map(function(x) {return x.id; }).indexOf(item.id))"
                    >
                      {{icons.mdiPencilBoxOutline}}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-else></v-progress-circular>
                    <v-icon
                      v-if="!is_done_internment"
                      class="mr-2"
                      color="success"
                      @click="done_internment(list_of_deceased_items_today.map(function(x) {return x.id; }).indexOf(item.id))"
                    >
                      {{icons.mdiCheck}}
                    </v-icon>
                    <v-progress-circular color="info" indeterminate
                                         v-else></v-progress-circular>
                  </div>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-row>
    </v-card>
    <update-amount
      v-on:dialog_show="on_close_dialog"
      :dialog_show="dialog_show"
      :data="data"
    ></update-amount>
    <done-internment
      v-on:dialog_show="on_close_dialog"
      :dialog_show="dialog_show_done"
      :data="data"
    ></done-internment>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiPrinter,
    mdiDelete,
    mdiCheck,
    mdiPencilBoxOutline
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import UpdateAmount from './UpdateAmount.vue'
  import DoneInternment from './DoneInternment.vue'

  const initialState = () => {
    return {
      list_of_deceased_items_today: [],
      is_done_internment: false,

      dialog_show: false,
      dialog_show_done: false,
      data: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
      UpdateAmount,
      DoneInternment
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiPrinter,
          mdiDelete,
          mdiCheck,
          mdiPencilBoxOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    created() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch_id', 'month_start', 'month_end', 'user_id',]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deceased_client', ['get_list_of_deceased_dashboard']),
      initialize_data() {
        this.get_list_of_deceased_dashboard({
          search: '>',
          branch_id: this.branch_id,
        })
          .then(response => {
            this.list_of_deceased_items_today = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      on_close_dialog(value) {
        this.dialog_show = value
        this.dialog_show_done = value
        Object.assign(this.$data, initialState())
        this.initialize_data()
      },
      edit_charge(index) {
        this.data = this.list_of_deceased_items_today[index];
        this.dialog_show = true
      },
      done_internment(index) {
        this.data = this.list_of_deceased_items_today[index];
        this.dialog_show_done = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .greeting-card {
    position: relative;

    .greeting-card-bg {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .greeting-card-trophy {
      position: absolute;
      bottom: 10%;
      right: 8%;
    }
  }

  // rtl
  .v-application {
    &.v-application--is-rtl {
      .greeting-card-bg {
        right: initial;
        left: 0;
        transform: rotateY(180deg);
      }

      .greeting-card-trophy {
        left: 8%;
        right: initial;
      }
    }
  }
</style>
