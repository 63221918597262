<template>
  <div>
    <v-layout column wrap>
      <v-dialog v-model="dialog_show" persistent max-width="50%">
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">INTERNMENT FORM</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="date_of_interment"
                    label="Date of Interment"
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="fsc_no"
                    label="FSC No."
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="time_internment"
                    label="Time of Intement"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    :rules="rules.default_max_255_character_and_no_empty_rule"
                    v-model="address_internment"
                    label="Interment At"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="helper_name"
                    label="Helper Name 1"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="driver_name"
                    label="Driver Name 1"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="helper_name_2"
                    label="Helper Name 2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="driver_name_2"
                    label="Driver Name 2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="helper_name_3"
                    label="Helper-Equipment Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="6" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="driver_name_3"
                    label="Driver Name 3"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="12" cols="12">
                  <v-text-field
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                    v-model="collateral"
                    label="Collateral"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="mx-1" @click="save_details" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
              <v-btn class="mx-1" color="error" @click="close_dialog()">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-layout>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      fsc_no: '',
      time_internment: '',
      helper_name: '',
      address_internment: '',
      driver_name: '',
      collateral: '',
      date_of_interment: '',

      helper_name_2: '',
      driver_name_2: '',
      helper_name_3: '',
      driver_name_3: '',
      balance: '0',
      date_of_service: '',
      date_of_internment: '',
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    props: {
      dialog_show: Boolean,
      data: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      dialog_show: function () {
        this.fsc_no = this.data.fsc_no
        this.time_internment = ''
        this.helper_name = ''
        this.address_internment = ''
        this.driver_name = ''
        this.collateral = ''
        this.date_of_interment = this.data.date_of_internment
        this.helper_name_2 = ''
        this.driver_name_2 = ''
        this.helper_name_3 = ''
        this.driver_name_3 = ''
        this.balance = this.data.balance
        this.date_of_service = this.data.date_of_service
        this.date_of_internment = this.data.date_of_internment
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deceased_client', ['done_interment']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      close_dialog() {
        this.$emit('dialog_show', false)
      },
      save_details() {
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.data.id)
          data.append('time_internment', this.time_internment)
          data.append('address_internment', this.address_internment)
          data.append('helper_name', this.helper_name.toUpperCase())
          data.append('driver_name', this.driver_name.toUpperCase())
          data.append('collateral', this.collateral.toUpperCase())
          this.done_interment(data)
            .then(response => {
              this.printingInformation()
              this.change_snackbar({
                show: true,
                color: 'success',
                text: response.data,
              })
              this.$emit('dialog_show', false)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      printingInformation() {
        var imgData = this.company_logo
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'INTERMENT FORM', style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      }
                    },
                    {
                      text: 'FSC No.: ' + this.fsc_no, style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      }
                    },
                  ]
                },
              ]
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Service: ',
                        {text: this.date_of_service, style: 'main_data'}
                      ]
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Date of Interment: ',
                        {
                          text: this.date_of_internment,
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name of Cadaver: ',
                        {
                          text: this.data.last_name + ', ' + this.data.first_name + ' ' + this.data.middle_name,
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
              ]
            },
            '_______________________________________________________________________________________________',
            'INTERMENT DATA',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Interment Time: ',
                        {
                          text: this.time_internment,
                          style: 'main_data'
                        }
                      ]
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Interment Address: ',
                        {
                          text: this.address_internment.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Driver Name 1: ',
                        {
                          text: this.driver_name.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Helper Name 1: ',
                        {
                          text: this.helper_name.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Driver Name 2: ',
                        {
                          text: this.driver_name_2.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Helper Name 2: ',
                        {
                          text: this.helper_name_2.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Driver Name 3: ',
                        {
                          text: this.driver_name_3.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Helper-Equipment Name: ',
                        {
                          text: this.helper_name_3.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Collateral: ',
                        {
                          text: this.collateral.toUpperCase(),
                          style: 'main_data'
                        }
                      ]
                    },
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' '
                    },
                    {
                      text: ' '
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: '_____________________________',
                    },
                    {
                      text: '_____________________________',
                    },
                    {
                      text: '_____________________________',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: 'CASHIER',
                    },
                    {
                      text: 'CHAPEL MANAGER',
                    },
                    {
                      text: 'AUDITOR',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' '
                    },
                    {
                      text: ' '
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: 'DRIVER',
                    },
                    {
                      text: 'HELPER',
                    },
                  ]
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' '
                    },
                    {
                      text: ' '
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: 'DRIVER 2',
                    },
                    {
                      text: 'HELPER 2',
                    },
                  ]
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' '
                    },
                    {
                      text: ' '
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    }
                  ]
                },
                {
                  columns: [
                    {
                      text: 'DRIVER 3',
                    },
                    {
                      text: 'HELPER-EQUIPMENT',
                    },
                  ]
                },
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
            {
              columns: [
                {
                  text: ' '
                }
              ]
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },


        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
