<template>
  <div>
    <v-layout column wrap>
      <v-dialog v-model="dialog_show" persistent max-width="50%">
        <v-form ref="form" class="multi-col-validation mt-6">
          <v-card>
            <v-toolbar dense dark color="primary">
              <v-toolbar-title><h4 class="font-weight-light">SERVICE DATA INFORMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-col md="4" cols="12"></v-col>
              <v-row>
                <v-col md="4" cols="12">
                  <v-text-field
                    v-model="fsc_no"
                    label="FSC No."
                    dense
                    outlined
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    readonly
                    v-model="date_of_service"
                    label="Date of Service"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="4" cols="12">
                  <v-text-field
                    readonly
                    v-model="date_of_internment"
                    label="Date of Interment"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="kilometer"
                    label="Kilometers"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    readonly
                    v-model="kilometer_excess"
                    label="Kilometers Excess"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="days_of_embalming"
                    label="Day's of Embalming"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    readonly
                    v-model="embalming_excess"
                    label="Embalming Excess"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    readonly
                    v-model="viewing_type"
                    label="Viewing Type"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="chapel_view"
                    label="Viewing Days"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    readonly
                    v-model="viewing_excess"
                    label="Viewing Excess"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="shipment_fee"
                    label="Shipment Fee"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="tribute_fee"
                    label="Tribute Fee"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="hospital_bill"
                    label="Hospital Bill"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model="other_charges"
                    label="Other Charges"
                    dense
                    outlined
                    @change="calculate_all_payables"
                    @keyup="calculate_all_payables"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12" v-if="other_charges > 0">
                  <v-text-field
                    min="0"
                    v-model="others_reason"
                    label="Specify (Others)"
                    dense
                    outlined
                    :rules="rules.default_max_45_character_and_no_empty_rule"
                  ></v-text-field>
                </v-col>
                <v-col md="3" cols="12">
                  <v-text-field readonly v-model="total_format" label="Total" dense
                                outlined></v-text-field>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert color="warning" text class="mb-0">
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn color="primary" class="mx-1" @click="save_details" v-if="!saving"> Save
                changes
              </v-btn>
              <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                   v-else></v-progress-circular>
              <v-btn class="mx-1" color="error" @click="close_dialog()">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-layout>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountSearchOutline} from '@mdi/js'
  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import snackBarDialog from "@/components/dialogs/notifications_dialog/Snackbar";

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',

      fsc_no: '',
      date_of_service: '',
      date_of_internment: '',
      kilometer: 25,
      kilometer_excess: 0,
      days_of_embalming: 9,
      embalming_excess: 0,
      viewing_type_room: '',
      viewing_type: '',
      viewing_type_excess_charge: 0,
      chapel_view: 0,
      viewing_excess: 0,
      other_charges: 0,
      others_reason: '',
      shipment_fee: 0,
      tribute_fee: 0,
      hospital_bill: 0,
      total: 0,
      total_format: 0,
    };
  };
  export default {
    components: {
      snackBarDialog,
    },
    props: {
      dialog_show: Boolean,
      data: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountSearchOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    watch: {
      dialog_show: function () {
        this.fsc_no = this.data.fsc_no
        this.date_of_service = this.data.date_of_service
        this.date_of_internment = this.data.date_of_internment
        this.kilometer = this.data.kilometer
        this.kilometer_excess = this.data.kilometer_excess
        this.embalming_free_days = this.data.embalming_free_days
        this.embalming_excess_charge = this.data.embalming_excess_charge
        this.days_of_embalming = this.data.days_of_embalming
        this.embalming_excess = this.data.embalming_excess
        this.viewing_type_room = this.data.viewing_type_room
        this.viewing_type = this.data.viewing_type
        this.viewing_type_excess_charge = this.data.viewing_type_excess_charge
        this.chapel_view = this.data.chapel_view
        this.viewing_excess = this.data.viewing_excess
        this.other_charges = this.data.other_charges
        this.others_reason = this.data.others_reason
        this.shipment_fee = this.data.shipment_fee
        this.tribute_fee = this.data.tribute_fee
        this.hospital_bill = this.data.hospital_bill
        this.total = this.data.total
        this.total_format = this.formatPrice(this.data.total)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', ['branch', 'branch_id', 'year', 'month_start', 'month_end', 'month_of', 'user_id', 'branch_address', 'branch_contact_no']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deceased_client', ['update_details']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      close_dialog() {
        this.$emit('dialog_show', false)
      },
      calculate_all_payables() {
        this.total = parseFloat(this.data.total)
        //Kilometer
        if (parseInt(this.kilometer) <= parseInt(this.data.kilometer)) {
          this.kilometer = this.data.kilometer
          this.kilometer_excess = this.data.kilometer_excess
        } else {
          this.kilometer_excess = (this.kilometer - 25) * 50
        }

        this.date_of_internment = moment(this.date_of_service, 'YYYY-MM-DD')
          .add(parseInt(this.days_of_embalming), 'days')
          .format('MMMM D, YYYY')

        //Embalming
        if (parseInt(this.days_of_embalming) <= parseInt(this.data.days_of_embalming)) {
          this.days_of_embalming = this.data.days_of_embalming
          this.embalming_excess = this.data.embalming_excess
        } else {

          var days = this.data.embalming_free_days
          var caa = this.data.embalming_excess_charge
          if (this.data.mode_of_service === 'Tie Up') {
            var tie_up_name = this.data.tie_up
            var selected_casket = this.data.casket_type
            var pricing = 0;
            var free_days = 0;
            var charge = 0;
            this.data.tie_ups_data_items.forEach(function (item) {
              if (item.name === tie_up_name && item.casket_type === selected_casket) {
                pricing = item.casket_price
                free_days = item.embalming_free_days
                charge = item.embalming_excess_charge
              }
            })
            days = free_days
            caa = charge
          }
          this.embalming_excess = (this.days_of_embalming - days) * caa
        }


        //Chapel View
        if (parseInt(this.chapel_view) <= parseInt(this.data.chapel_view)) {
          this.chapel_view = this.data.chapel_view
          this.viewing_excess = this.data.viewing_excess
        } else {
          this.viewing_type_excess_charge = this.data.viewing_type_excess_charge
          var chapel_view_free = 0
          if (chapel_view_free > 0) {
            switch (this.data.viewing_type) {
              case 'TOPAZ NON-AIR':
              case 'SAPPHIRE NON-AIR':
                break
              default:
                chapel_view_free = 0
                break
            }
          }
          this.viewing_excess = (this.chapel_view - chapel_view_free) * this.viewing_type_excess_charge
        }

        //Shipment Fee
        if (parseFloat(this.shipment_fee) <= parseInt(this.data.shipment_fee)) {
          this.shipment_fee = this.data.shipment_fee
        }
        //Tribute Fee
        if (parseFloat(this.tribute_fee) <= parseInt(this.data.tribute_fee)) {
          this.tribute_fee = this.data.tribute_fee
        }
        //Hospital Bill
        if (parseFloat(this.hospital_bill) <= parseInt(this.data.hospital_bill)) {
          this.hospital_bill = this.data.hospital_bill
        }
        //Other Charge
        if (parseFloat(this.other_charges) <= parseInt(this.data.other_charges)) {
          this.other_charges = this.data.other_charges
        }

        this.total = parseFloat(this.data.total)
          + (parseFloat(this.other_charges) - parseFloat(this.data.other_charges))
          + (parseFloat(this.hospital_bill) - parseFloat(this.data.hospital_bill))
          + (parseFloat(this.tribute_fee) - parseFloat(this.data.tribute_fee))
          + (parseFloat(this.shipment_fee) - parseFloat(this.data.shipment_fee))
          + (parseFloat(this.viewing_excess) - parseFloat(this.data.viewing_excess))
          + (parseFloat(this.embalming_excess) - parseFloat(this.data.embalming_excess))
          + (parseFloat(this.kilometer_excess) - parseFloat(this.data.kilometer_excess))

        this.total_format = this.formatPrice(this.total)
      },
      save_details() {
        if (parseFloat(this.total) <= parseFloat(this.data.total)) {
          this.alert = true
          this.alert_message = 'Nothing Changes'
          this.saving = false
        } else {
          if (this.$refs.form.validate()) {
            const data = new FormData()
            data.append('id', this.data.id)
            data.append('date_of_internment', this.date_of_internment)
            data.append('kilometer', this.kilometer)
            data.append('kilometer_excess', this.kilometer_excess)
            data.append('days_of_embalming', this.days_of_embalming)
            data.append('embalming_excess', this.embalming_excess)
            data.append('chapel_view', this.chapel_view)
            data.append('viewing_excess', this.viewing_excess)
            data.append('other_charges', this.other_charges)
            data.append('others_reason', this.others_reason)
            data.append('shipment_fee', this.shipment_fee)
            data.append('tribute_fee', this.tribute_fee)
            data.append('hospital_bill', this.hospital_bill)
            data.append('total', this.total)
            this.update_details(data)
              .then(response => {
                this.change_snackbar({
                  show: true,
                  color: 'success',
                  text: response.data,
                })
                this.$emit('dialog_show', false)
              })
              .catch(error => {
                this.alert = true
                this.alert_message = error
                this.saving = false
              })
          } else {
            this.alert = true
            this.alert_message = 'Please Fill up the field/s'
            this.saving = false
          }
        }
      },
    }
  }
</script>
